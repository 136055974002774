/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


//----- Centurion Logo -----//
.cw-banner {
  content: url(./assets/cw-bw.png);
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  .cw-banner {
    content: url(./assets/cw-bw-dark.png);
  }
}

.errors {
	font-size: small;
	color: white;
	background: var(--ion-color-danger);
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
}

[hidden] {
  display: none !important;
}

//----- Code input -----//
.code-input {
  text-align: center;
  margin-bottom: 20px;
}

.code-input__items {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.code-input__item {
  display: inline-block;
  margin: 0px 2%;
  width: 5%;
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
}

@media (max-width: 800px) {
  .code-input__item {
    margin: 0px 2%;
    width: 12%;
  }
}

//----- Animations -----//
@keyframes slide-in-from-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

//----- Notification indicator -----//
.notification-indicator {
  background: var(--ion-color-primary);

  width: 10px;
  height: 10px;

  border-radius: 100%;

  position: absolute;
}

//----- Wrapper -----//
.wrap {
  white-space: normal;
}

//----- Signature image -----//
.signature-image{
  max-height: 250px;
  max-width: 250px;
  margin: auto;
}